import {Alert, Button, Dropdown, Menu, Modal, Space, Table, Tag} from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import React, {useEffect, useState} from 'react';
import moment from "moment";
import FileThumb from "./FileThumb";
import {doc, deleteDoc, getFirestore, updateDoc} from "firebase/firestore";

const TotalList = (props) => {
    const {data,getTotal} = props
    const [tableData, setTableData] = useState([])
    const [visible, setVisible] = useState(false)
    const [alertVisible, setAlertVisible] = useState(false)
    const [item, setItem] = useState({});
    const db = getFirestore();

    const getStatusColor = (status)=>{
        let color = "geekblue"
        switch (status) {
            case "upload":
                color = "volcano"
                break
            case "get":
                color = "geekblue"
                break
            case "finish":
                color = "green"
        }
        return color;
    }

    const getTermsColor = (terms)=>{
        let color = "volcano"
        switch (terms) {
            case "true":
                color = "green"
                break
            case true:
                color = "green"
            default:
                color = "volcano"
        }
        return color;
    }

    const onMenuClick = (record, e) => {
        console.log('Update Status', record, e);

        const status = e.key;
        const dolphinRef = doc(db, "dolphins", record.key);

        updateDoc(dolphinRef, {
            status: status
        });

        getTotal()

        setAlertVisible(true)
    };

    const menu  =(record)=> {
        return(
            <Menu
                onClick={onMenuClick.bind(this,record)} record={record}
                items={[
                    {
                        key: 'upload',
                        label: 'Upload',
                        record: record,
                    },
                    {
                        key: 'get',
                        label: 'Get',
                        record: record,
                    },
                    {
                        key: 'finish',
                        label: 'Finish',
                        record: record,
                    },
                ]}
            />
        )
    };

    const columns = [
        {
            title: 'Terms',
            dataIndex: 'terms',
            key: 'terms',
            render: (_, { terms }) => (
                <>
                    <Tag color={getTermsColor(terms)} key={terms}>
                        {terms.toUpperCase()}
                    </Tag>
                </>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            // filteredValue: filteredInfo.name || null,
            filterSearch: true,
            onFilter: (value, record) => record.name.startsWith(value),
            //sorter: (a, b) => a.name.length - b.name.length,
            // sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
            width: '20%',
        },
        {
            title: 'File',
            dataIndex: 'url',
            key: 'url',
            render: (_, { url }) => (
                <FileThumb url={url} />
            ),
        },
        {
            title: 'Create',
            dataIndex: 'create',
            key: 'create',
            sorter: (a, b) => a.create - b.create,
            render: (_, { create }) => (
                <>
                    {moment(create).format("YYYY-MM-DD hh:mm:ss")}
                </>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            sorter: (a, b) => a.status.length - b.status.length,
            render: (_, { status }) => (
                <>
                    <Tag color={getStatusColor(status)} key={status}>
                        {status.toUpperCase()}
                    </Tag>
                </>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            width: '10%',
            render: (_, record) => (
                <Space>
                    <Dropdown overlay={menu.bind(this,record)} record={record} >
                        <Button>
                            <Space>
                                Status
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                    <Button type="primary" danger onClick={(e)=>{
                        console.log(record)
                        setItem(record)
                        showModal()
                    }}>Delete</Button>
                </Space>
            ),
        },
    ];

    useEffect(()=>{
        console.log(">>> update data")
        if(data){
            let temp = []
            data.forEach((doc) => {
                const d = doc.data()
                if(d.id == "p9307xNmWalGzLtn2Avs") {
                    console.log(d)
                }
                temp.push({
                    key: d.id,
                    name: d.name,
                    file: d.file,
                    url: d.url,
                    status: d.status,
                    create: d.create.toDate(),
                    terms: d.terms ? "true" : "false",
                })
            });
            setTableData(temp)
        }
    }, [data])

    const showModal = () => {
        setVisible(true);
    };

    const hideModal = () => {
        setVisible(false);
    };

    const deleteItem = ()=>{
        console.log("delete: " + item)
        hideModal()

        deleteDoc(doc(db, "dolphins", item.key)).then(()=>{
            getTotal()
        })
    }

    return(
        <>
            { alertVisible ?
            <Alert
                message="Update"
                description="The status hase been updated."
                type="warning"
                showIcon
                closable
                banner
                onClose={()=>{
                    setAlertVisible(false)
                }}
            /> : <></> }

            <h1>Table</h1>
            <Table columns={columns} dataSource={tableData} />
            <Modal
                title="Delete"
                visible={visible}
                onOk={deleteItem}
                onCancel={hideModal}
                okText="Delete"
                cancelText="Cancel"
            >
                <p><strong>{item.name}</strong> ({item.key}) 를 삭제하시겠습니까? </p>
            </Modal>

        </>
    )
}

export default TotalList