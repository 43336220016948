import React, {useState, Suspense} from 'react'
import {Canvas, useFrame} from '@react-three/fiber'
import * as THREE from "three"
import Dolphin from "./Dolphin"
import {
    Button,
    CenterPopup,
    Checkbox,
    Dialog,
    Form,
    Image,
    ImageUploader,
    Input,
    NoticeBar,
    Space,
    TextArea
} from "antd-mobile";
import {collection, doc, getFirestore, increment, serverTimestamp, setDoc, updateDoc} from "firebase/firestore";
import {deleteObject, getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import {v4 as uuidv4} from "uuid";

import {App, AppTitle, Title} from "./Style";

const Basic = (props) => {
    const {setUpload} = props
    const [fileList, setFileList] = useState([])
    const [fileRef, setFileRef] = useState(null)
    const maxCount = 1

    const onDelete = file => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList)
        setUpload(null)

        if(fileRef){
            console.log("delete: " + fileRef.fullPath)
            deleteObject(fileRef)
            setFileRef(null)
        }
    }

    const customUpload = async (file) => {
        const metadata = {
            contentType: file.type
        }
        const ext = file.name.split('.').pop()
        const imagePath = `images/${uuidv4()}.${ext}` ; //a unique name for the image
        const storage = getStorage();
        const imgRef = ref(storage, imagePath);
        try {
            const image = await uploadBytes(imgRef, file, metadata);
            setUpload(image)
            const url = await getDownloadURL(image.ref)
            setFileRef(image.ref)
            return {
                url: url
            }
        } catch(e) {
            console.log(e)
        }
    }

    return (
        <ImageUploader
            maxCount={maxCount}
            value={fileList}
            showUpload={fileList.length < maxCount}
            onChange={setFileList}
            upload={customUpload}
            //beforeUpload={beforeUpload}
            onDelete={onDelete}
        />
    )
}

const Upload = () => {
    const [form] = Form.useForm()
    const [value, setValue] = useState('')
    const [upload, setUpload] = useState(null)
    const [image, setImage] = useState('')
    const [finish, setFinish] = useState(false)
    const [terms, setTerms] = useState(false)
    const [popup, setPopup] = useState(true)


    const Rig = ()=>{
        return useFrame((state) => {
            state.camera.position.x = THREE.MathUtils.lerp(state.camera.position.x, 1 + state.mouse.x / 4, 0.075)
            state.camera.position.y = THREE.MathUtils.lerp(state.camera.position.y, 1.5 + state.mouse.y / 4, 0.075)
        })
    }

    const onSubmit = () => {
        form.validateFields().then( async values => {
            if(upload===null){
                Dialog.show({
                    content: '이미지를 올려주세요',
                    closeOnAction: true,
                    actions: [
                        {
                            key: 'cancel',
                            text: '확인',
                        }
                    ]
                })
                return
            }
            console.log(values)

            const url = await getDownloadURL(upload.ref)
            const db = getFirestore();
            const docRef = doc(collection(db, "dolphins"));
            await setDoc(docRef, {
                'id': docRef.id,
                'create': serverTimestamp(),
                'name': values.dolphin_name,
                'message': values.dolphin_message === undefined ? "" : values.dolphin_message,
                'status': 'upload',
                'terms': terms,
                'length': 15,
                'file': "/"+upload.ref.fullPath,
                'url': url,
            });

            const configRef = doc(db, "config", "dolphins");
            await updateDoc(configRef, {
                count: increment(1)
            })

            setImage(url)
            setFinish(true)
        })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    return(
        <>

            <App>
                <Canvas concurrent shadowMap camera={{ position: [1, 1.5, 2.5], fov: 50 }}>
                    <ambientLight />
                    <directionalLight position={[-5, 5, 5]} castShadow shadow-mapSize-width={1024} shadow-mapSize-height={1024} />
                    <group position={[0, -1, 0]}>
                        <Suspense fallback={null}>
                            <Dolphin path={"/dolphins/textures/tex_0004.png"} />
                        </Suspense>
                    </group>
                    <mesh rotation={[-0.5 * Math.PI, 0, 0]} position={[0, -1, 0]} receiveShadow>
                        <planeBufferGeometry args={[10, 10, 1, 1]} />
                        <shadowMaterial transparent opacity={0.2} />
                    </mesh>
                    <Rig />
                </Canvas>
            </App>

            <AppTitle>열어,보다</AppTitle>
            <Title>Upload</Title>

            <div style={{zIndex:9999, position:"relative"}}>
                {finish === false &&
                    <Form
                        form={form}
                        footer={
                            <Button block color='primary' onClick={onSubmit}>
                                등록
                            </Button>
                        }
                    >
                        {/*<h3 >&nbsp; 당신의 돌고래를 남겨주세요.</h3>*/}
                        <Form.Item name='dolphin_name' label='돌고래 이름' rules={[{ required: true, message: '돌고래 이름을 정해주세요.' }]}>
                            <Input placeholder='삼팔이' />
                        </Form.Item>

                        <Form.Item name='dolphin_message' label='하고싶은 말'>
                            <TextArea
                                placeholder='남기고 싶은 말을 적어주세요'
                                value={value}
                                rows={3}
                                onChange={val => {
                                    setValue(val)
                                }}
                            />
                        </Form.Item>

                        <NoticeBar content='이미지는 정사각형으로 올려주세요.' color='alert' />

                        <Form.Item label='이미지'>
                            <Basic setUpload={setUpload}/>
                        </Form.Item>

                        <NoticeBar content='사용동의 한 이미지는 BNFT를 통해 기부금 모음에 사용 됩니다.' color='alert' />

                        <Space justify='center' block style={{ '--gap': '24px', margin: '12px' }} >
                            <Checkbox onChange={checked => {
                                setTerms(checked)
                            }} >이미지 사용동의</Checkbox>
                            <Button size='small' color={"default"} onClick={()=>{
                                window.open("/terms", '_blank')
                            }} >이용약관</Button>
                        </Space>


                    </Form>
                }
                {
                    finish &&
                    <>
                        <Space justify='center' block>
                            <Image
                                src={image}
                                width={256}
                                height={256}
                                fit='cover'
                                style={{ borderRadius: 128 }}
                            />
                        </Space>
                        <br/>
                        <br/>
                        <Space justify='center' block>
                            <h1>돌고래를 남겨 주셔서 감사합니다.</h1>
                        </Space>
                        <br/>
                        <Space justify='center' block>
                            <div style={{width: "260px"}}>남겨주신 돌고래는 '열어,보다' 앱을 통해 확인 할 수 있습니다.</div>
                        </Space>
                        <br/>
                        <br/>
                        <br/>
                        <Space justify='center' block>
                            <a href="https://apps.apple.com/app/id1403244493"
                               target="_blank" rel="noreferrer">
                                <img src="./app-store-badge-kr.png" width="128" />
                            </a>
                            <br/>
                            <br/>
                            <a href="https://play.google.com/store/apps/details?id=com.sewonist.tlm"
                               target="_blank" rel="noreferrer">
                                <img src="./google-play-badge-kr.png" width="128"/>
                            </a>
                        </Space>

                    </>
                }
            </div>

            <div style={{padding:"10px", marginTop:"20px", backgroundColor:"white"}}>
                <Button block color='default' onClick={()=>{
                    // window.location.href = "/tutorial"
                }} >
                    BNFT 안내
                </Button>
                <br/>
                <Button block color='default' onClick={()=>{
                    window.location.href = "/tutorial"
                }} >
                    사용방법 안내
                </Button>
                <br/>
                <Button block color='default' onClick={()=>{
                    window.location.href = "/download"
                }} >
                    돌고래 도안 다운로드
                </Button>
            </div>

            {/*<Footer />*/}
        </>
    )
}
export default Upload