import {Suspense, useEffect, useState} from "react";
import {Canvas, useFrame} from '@react-three/fiber'
import * as THREE from "three"
import Dolphin from "./Dolphin"
import {Button, Space} from "antd-mobile";
import axios from "axios";
import fileDownload from "js-file-download";
import {App, AppTitle, Title} from "./Style";

const Download = () => {

    const Rig = ()=>{
        return useFrame((state) => {
            state.camera.position.x = THREE.MathUtils.lerp(state.camera.position.x, 1 + state.mouse.x / 4, 0.075)
            state.camera.position.y = THREE.MathUtils.lerp(state.camera.position.y, 1.5 + state.mouse.y / 4, 0.075)
        })
    }

    const onClickDownload = (url, filename)=>{
        // axios.get(url, {
        //     responseType: 'blob',
        // })
        //     .then((res) => {
        //         fileDownload(res.data, filename)
        //     })
        window.open(url, filename)
    }

    return(
        <>
            <App>
                <Canvas concurrent shadowMap camera={{ position: [1, 1.5, 2.5], fov: 50 }}>
                    <ambientLight />
                    <directionalLight position={[-5, 5, 5]} castShadow shadow-mapSize-width={1024} shadow-mapSize-height={1024} />
                    <group position={[0, -1, 0]}>
                        <Suspense fallback={null}>
                            <Dolphin path={"/dolphins/textures/tex_0003.png"} />
                        </Suspense>
                    </group>
                    <mesh rotation={[-0.5 * Math.PI, 0, 0]} position={[0, -1, 0]} receiveShadow>
                        <planeBufferGeometry args={[10, 10, 1, 1]} />
                        <shadowMaterial transparent opacity={0.2} />
                    </mesh>
                    <Rig />
                </Canvas>
            </App>

            <AppTitle>열어,보다</AppTitle>
            <Title>Download</Title>

            <div style={{padding:"10px", backgroundColor:"white"}}>
                <img src={"/badaboda_drawing.png"} style={{width:"100%"}} />
                <br/><br/>
                <Button block color='primary' onClick={()=>onClickDownload("/badaboda_drawing.png", "badaboda_drawing.png")} >
                    PNG 다운로드
                </Button>
                <br/>
                <Button block color='primary' onClick={()=>onClickDownload("/badaboda_drawing.pdf", "badaboda_drawing.pdf")} >
                    PDF 다운로드
                </Button>
                <br/>
                <ul>
                    <li>갤러리에서 다운로드 받은 이미지를 꾸민 후 도안을 등록해주세요.</li>
                    <li>이미지를 다운로드 받은 후 출력하여 그림을 그리고 촬영된 데이터를 등록해주셔도 됩니다.</li>
                </ul>
                <br/>
                <Button block color='default' onClick={()=>{
                    window.location.href = "/tutorial"
                }} >
                    사용방법 안내
                </Button>
                <br/>
                <Button block color='default' onClick={()=>{
                    window.location.href = "/upload"
                }} >
                    돌고래 도안 올리기
                </Button>
            </div>

            {/*<Footer />*/}
        </>


    )
}
export default Download