import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {CardActionArea, CardMedia} from "@mui/material";

export default function ItemCard(props) {
  const {name, file, status, create, url} = props

  return (
  <Card sx={{ maxWidth: 345 }}>
      <CardActionArea href={url} target={"_blank"}>
          <CardMedia
            component="img"
            height="320"
            image={url}
            alt={name}
          />
      </CardActionArea>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {create}
        </Typography>
      </CardContent>
  </Card>
  );
}