
import React, { useEffect, useState } from "react"
import { useGLTF, useTexture, useAnimations } from "@react-three/drei"
import { a, useSpring } from "@react-spring/three"

export default function Dolphin(props) {
    const {path} = props
    const texturePath = path ? path : "/dolphins/textures/tex_0001.jpg"
    // Fetch model and a separate texture
    const { nodes, animations, asset } = useGLTF("/dolphins/models/dolphin_0001.glb")
    const texture = useTexture(texturePath)
    // Extract animation actions
    const { ref, actions, names } = useAnimations(animations)
    // Hover and animation-index states
    const [hovered, setHovered] = useState(false)
    const [index, setIndex] = useState(0)
    // Animate the selection halo
    const { color, scale } = useSpring({ scale: hovered ? [1.15, 1.15, 1] : [1, 1, 1], color: hovered ? "hotpink" : "aquamarine" })
    // Change cursor on hover-state
    useEffect(() => void (document.body.style.cursor = hovered ? "pointer" : "auto"), [hovered])
    // Change animation when the index changes
    useEffect(() => {
        // Reset and fade in animation after an index has been changed
        actions[names[index]].reset().fadeIn(0.5).play()
        // In the clean-up phase, fade it out
        return () => actions[names[index]].fadeOut(0.5)
    }, [index, actions, names])

    return (
        <group ref={ref} {...props} dispose={null} position={[0, 1, 0]} >
            <group position={[0.3, 1, 0]} rotation={[Math.PI / 2, 0.1, 0.4]} scale={0.01}>
                <primitive object={nodes.Root} />
                <skinnedMesh
                    castShadow
                    receiveShadow
                    onPointerOver={() => setHovered(true)}
                    onPointerOut={() => setHovered(false)}
                    //onClick={() => setIndex((index + 1) % names.length)}
                    geometry={nodes.Mesh.geometry}
                    skeleton={nodes.Mesh.skeleton}
                    rotation={[-Math.PI / 2, 0, 0]}
                    scale={1}>
                    <meshStandardMaterial map={texture} map-flipY={false}  skinning />
                </skinnedMesh>
            </group>
            <a.mesh receiveShadow position={[0, 1, -1]} scale={scale}>
                <circleGeometry args={[1, 64]} />
                <a.meshStandardMaterial color={color} />
            </a.mesh>
        </group>
    )
}
