// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA657Zct9BlAT7qWEo02IfF7F8sCKHb7F4",
  authDomain: "the-layered-moments.firebaseapp.com",
  projectId: "the-layered-moments",
  storageBucket: "the-layered-moments.appspot.com",
  messagingSenderId: "1058771106438",
  appId: "1:1058771106438:web:33d30417da1efbe6f72f07"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;