import './App.css';
import store from "./redux/store";
import Router from "./components/Router";
import {Provider} from "react-redux";
import firebaseApp from "./FirebaseApp";

function App() {
  const app = firebaseApp
  return (
    <Provider store={store}>
      <Router/>
    </Provider>
  );
}

export default App;
