import { initializeApp } from "firebase/app";
import {getFirestore, collection, query, orderBy, getDocs, doc, updateDoc} from "firebase/firestore";
import {useState, useEffect} from "react";
import ItemCard from "./ItemCard";
import StackGrid from "react-stack-grid";
import {Box, Button, Container, Grid} from "@mui/material";
import TotalCard from "./TotalCard";
import TotalList from "./TotalList";
import {getDownloadURL, getStorage, ref} from "firebase/storage";



const Total = () => {
  const [total, setTotal] = useState(0)
  const [snapshot, setSnapShot] = useState(null)
  const db = getFirestore();
  const storage = getStorage();

  console.log("===  Call Total ===")

  const getTotal = async () => {
    const ref = collection(db, "dolphins");
    const q = query(ref, orderBy("create", "desc"));
    const querySnapshot = await getDocs(q);
    console.log("Total: " + querySnapshot.size)
    setTotal(querySnapshot.size)
    setSnapShot(querySnapshot)
  }

  useEffect(()=>{
    getTotal()
  }, [])


  const resetDolphin = async (id, status) => {
    const dolphinRef = doc(db, "dolphins", id);
    await updateDoc(dolphinRef, {
      status: status
    });
  }

  const updateAllStatus = async (status)=>{
    const q = collection(db, "dolphins");
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      resetDolphin(doc.id, status)
    });
    alert("Complete update : " + status)
  }

  const updateUrl = (data) => {
    getDownloadURL( ref(storage, data.file) ).then((url)=>{
      console.log("file: " + data.file + ", url: " + url)
      const dolphinRef = doc(db, "dolphins", data.id);
      updateDoc(dolphinRef, {
        url: url
      }).then(()=>{console.log("Complete update url")});
    })
  }

  const updateAllUrl = async () => {
    const q = collection(db, "dolphins");
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      //console.log(doc.id, " => ", doc.data());
      const data = doc.data();
      if(!data.url) updateUrl(data)
    });
  }

  return(
    <Container>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <TotalList data={snapshot} getTotal={getTotal}/>
        </Grid>

        <Grid item xs={12}>
          <h1>Total : {total}</h1>
        </Grid>
        <Grid item xs={12}>
          <Box mr={2} mt={2}>
            <Button variant="contained" onClick={() => {
              updateAllStatus("upload")
            }}>All Upload</Button>
          </Box>
          <Box mr={2} mt={2}>
            <Button variant="contained" onClick={() => {
              updateAllStatus("get")
            }}>All Get</Button>
          </Box>
          <Box mr={2} mt={2}>
            <Button variant="contained" onClick={() => {
              updateAllStatus("finish")
            }}>All Finish</Button>
          </Box>
          <Box mr={2} mt={2}>
            <Button variant="contained" onClick={() => {
              updateAllUrl()
            }}>Update All Url</Button>
          </Box>
        </Grid>

      </Grid>
    </Container>
  )
}
export default Total