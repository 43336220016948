import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, updateDoc, getDocs } from "firebase/firestore";
import {useState} from "react";

const Reset = () => {
  const db = getFirestore();
  const [total, setTotal] = useState(0)

  console.log("=== Reset ===")

  const resetDolphin = async (id) => {
    const dolphinRef = doc(db, "dolphins", id);
    await updateDoc(dolphinRef, {
      status: "upload"
    });
  }

  const resetConfig = async (total) => {
    const dolphinRef = doc(db, "config", "dolphins");
    await updateDoc(dolphinRef, {
      count: total
    });
  }

  const getTotal = async () => {
    const q = collection(db, "dolphins");
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      resetDolphin(doc.id)
    });
    console.log("Total: " + querySnapshot.size)
    setTotal(querySnapshot.size)
    resetConfig(querySnapshot.size)
  }
  getTotal()

  return(
    <div>Reset: {total}</div>
  )
}
export default Reset