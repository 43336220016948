import {connectFunctionsEmulator, getFunctions, httpsCallable} from "firebase/functions";
import {getApp} from "firebase/app";


const Set = () => {

  console.log("=== Set ===")
  const functions = getFunctions(getApp());
  connectFunctionsEmulator(functions, "localhost", 5001);
  const setFinishDolphin = httpsCallable(functions, 'setFinishDolphin');
  setFinishDolphin({id:"vAZShpASwKNCyyJVBLfv"}).then((result) => {
    console.log(result)
  });

  return(
    <div>Set</div>
  )
}
export default Set