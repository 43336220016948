
import {Route} from "react-router-dom";
import Set from "./Set";
import Get from "./Get";
import Upload from "./Upload";
import Total from "./Total";
import Reset from "./Reset";
import Gallery from "./Gallery";
import Download from "./Download";
import Support from "./Support";
import Tutorial from "./Tutorial";
import Terms from "./Terms";
import About from "./About";
import {ConfigProvider} from "antd-mobile";
import koKR from "antd-mobile/es/locales/ko-KR";
import OpenSee from "./OpenSee";


const Router = () => {
  return(
    <>
      <Route exact path="/"><OpenSee/></Route>
      <Route exact path="/upload"><ConfigProvider locale={koKR}><Upload/></ConfigProvider></Route>
      <Route exact path="/get"><Get/></Route>
      <Route exact path="/set"><Set/></Route>
      <Route exact path="/total"><Total/></Route>
      <Route exact path="/reset"><Reset/></Route>
      <Route exact path="/gallery"><Gallery/></Route>
      <Route exact path="/download"><Download/></Route>
      <Route exact path="/support"><Support/></Route>
      <Route exact path="/tutorial"><Tutorial/></Route>
      <Route exact path="/terms"><Terms/></Route>
    </>
  )
}
export default Router