import {collection, getDocs, getFirestore} from "firebase/firestore";
import {useEffect, useState} from "react";
import ItemCard from "./ItemCard";
import StackGrid from "react-stack-grid";
import InfiniteScroll from "react-infinite-scroll-component";

const TotalCard = (props) => {
    const {data} = props;
    const [hasMore, setHasMore] = useState(true)
    const [model, setModel] = useState([])
    const [items, setItems] = useState([])

    const createModel = () => {
        console.log("----- createModel ------")
        const result = []
        data.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            const d = doc.data()
            const createDate = d.create.toDate().toLocaleDateString()
            result.push({
                key: d.id,
                name: d.name,
                file: d.file,
                url: d.url,
                status: d.status,
                date: createDate,
            })
        });

        setModel(result)
    }

    const fetchData = () => {
        console.log("----- fetchData ------")

        const count = 10;
        let temp = [];
        const offset = items.length+count < model.length ? items.length+count : model.length
        console.log("offset: " + offset)
        for (let i = 0; i < offset; i++) {
            temp.push(model[i])
        }
        setItems(temp)
        if(offset==model.length) setHasMore(false)
    }

    useEffect(()=>{
        console.log("----- load ------")
        console.log(data)
        if(data) createModel()
    }, [data])

    useEffect(()=>{
        if(model.length>0) fetchData()
    }, [model])

    return(
        <>
            {items?.length>0 &&
                <InfiniteScroll
                    dataLength={items.length} //This is important field to render the next data
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>- fin -</b>
                        </p>
                    }
                >
                    <StackGrid gutterWidth={20} gutterHeight={40}
                               columnWidth={320}
                    >
                    {items.map((i, index) => (
                        <ItemCard
                            key={i.key}
                            name={i.name}
                            file={i.file}
                            url={i.url}
                            status={i.status}
                            create={i.date}/>
                    ))}
                    </StackGrid>
                </InfiniteScroll>
            }
        </>
    )
}
export default TotalCard