import {Suspense, useEffect, useState} from "react";
import {Canvas, useFrame} from '@react-three/fiber'
import styled from "styled-components";
import * as THREE from "three"
import Dolphin from "./Dolphin"
import {Button, Space} from "antd-mobile";
import StackGrid from "react-stack-grid";
import Footer from "./Footer";


import {App, AppTitle, Title} from "./Style";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

const Tutorial = () => {

    const Rig = ()=>{
        return useFrame((state) => {
            state.camera.position.x = THREE.MathUtils.lerp(state.camera.position.x, 1 + state.mouse.x / 4, 0.075)
            state.camera.position.y = THREE.MathUtils.lerp(state.camera.position.y, 1.5 + state.mouse.y / 4, 0.075)
        })
    }


    return(
        <>
            <Navbar bg="light" expand="lg" fixed="top">
                <Container>
                    <Navbar.Brand href="/#page-top">열어,보다</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" >
                        <Nav className="me-auto">
                        </Nav>
                        <Nav >
                            <Nav.Link href="/#features">Features</Nav.Link>
                            <Nav.Link href="/#download">Download</Nav.Link>
                            <Nav.Link href="/gallery">Gallery</Nav.Link>
                            <Nav.Link href="/tutorial">Tutorial</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <App>
                <Canvas concurrent shadowMap camera={{ position: [1, 1.5, 2.5], fov: 50 }}>
                    <ambientLight />
                    <directionalLight position={[-5, 5, 5]} castShadow shadow-mapSize-width={1024} shadow-mapSize-height={1024} />
                    <group position={[0, -1, 0]}>
                        <Suspense fallback={null}>
                            <Dolphin path={"/dolphins/textures/tex_0002.png"} />
                        </Suspense>
                    </group>
                    <mesh rotation={[-0.5 * Math.PI, 0, 0]} position={[0, -1, 0]} receiveShadow>
                        <planeBufferGeometry args={[10, 10, 1, 1]} />
                        <shadowMaterial transparent opacity={0.2} />
                    </mesh>
                    <Rig />
                </Canvas>
            </App>

            <AppTitle> </AppTitle>
            <Title>Tutorial</Title>

            <StackGrid columnWidth={344} >
                <img src={"/tutorial/0001.png"} height={"346"} /><br/>
                <img src={"/tutorial/0002.png"} height={"346"} /><br/>
                <img src={"/tutorial/0003.png"} height={"346"} /><br/>
                <img src={"/tutorial/0004.png"} height={"346"} /><br/>
            </StackGrid>

            <div style={{
                padding: "20px 12px"
            }}>
                <Button block color='default' onClick={()=>{
                    window.location.href = "/download"
                }}>
                    돌고래 도안 다운로드
                </Button>
                <br/>
                <Button block color='default' onClick={()=>{
                    window.location.href = "/upload"
                }}>
                    돌고래 도안 올리기
                </Button>
            </div>
            {/*<Footer />*/}
        </>


    )
}
export default Tutorial