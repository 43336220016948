import {Space} from "antd-mobile";
import styled from "styled-components";

const FooterText = styled.div`
  color: #666666;
  font-size: 14px;
`

const FooterLink = styled.a`
  color: #666666;
  font-size: 14px;
`
const Divider = styled.span`
  padding-left: 20px;
  padding-right: 20px;
`

const Footer = ()=>{
    return(
        <div style={{marginTop: 100, paddingTop: 100, paddingBottom: 100, backgroundColor: "lightgray" }} >
            <Space justify='center' block>
                <FooterText>
                    <FooterLink href={"https://fragments2021.ink/#/8"} target={"_blank"}>전시소개</FooterLink>
                    <Divider>|</Divider>
                    <FooterLink href={"/gallery"} target={"_blank"}>갤러리</FooterLink>
                    <Divider>|</Divider>
                    <FooterLink href={"/terms"} target={"_blank"}>이용약관</FooterLink>
                </FooterText>
            </Space>
            <br/><br/>
            <Space justify='center' block>
                <a href="https://apps.apple.com/us/app/%EA%B2%B9%EC%B3%90%EC%A7%84-%EC%82%AC%EA%B1%B4%EB%93%A4/id1403244493"
                   target="_blank" rel="noreferrer">
                    <img src="/app-store-badge-kr.png" width="128" />
                </a>
                <br/>
                <br/>
                <a href="https://play.google.com/store/apps/details?id=com.sewonist.tlm"
                   target="_blank" rel="noreferrer">
                    <img src="/google-play-badge-kr.png" width="128"/>
                </a>
            </Space>
        </div>
    )
}
export default Footer