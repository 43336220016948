import './about.css';
import styled from "styled-components";

export const Page = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 1;
  padding: 20px;
`

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-size: cover;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  @media screen and (max-width: 768px) {
    top: 15vh;
    height: 70vh;
  }
`

export const AboutH1 = styled.h1`
  font-family: 나눔스퀘어, 'NanumSquare', sans-serif;
  color: white;
  margin: auto;
  text-align: center;
  vertical-align: middle;
  padding-top: 40pt;
  font-size: 3em;
  font-weight: 300
`

export const Bolder = styled.strong`
  font-weight: 800
`

export const AboutH2 = styled.h2`
  font-family: 나눔스퀘어, 'NanumSquare', sans-serif;
  color: white;
  margin: auto;
  text-align: center;
  vertical-align: middle;
  font-size: 1.8em;
  word-break: break-all;
`

export const AboutH3 = styled.h3`
  font-family: 나눔스퀘어, 'NanumSquare', sans-serif;
  color: white;
  margin: auto;
  text-align: center;
  vertical-align: middle;
  font-size: 1em;
  word-wrap: break-word;
  word-break: break-all;
`

export const Comment = styled.div`
  font-family: 나눔스퀘어, 'NanumSquare', sans-serif;
  color: white;
  margin: auto;
  text-align: left;
  vertical-align: bottom;
  font-size: 0.5em;
`