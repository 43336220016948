import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {CardActionArea} from "@mui/material";

export default function FileThumb(props) {
    const {url} = props
    return (
        <Card sx={{ maxWidth: 120 }}>
            <CardActionArea href={url}>
                <CardMedia
                    component="img"
                    height="120"
                    image={url}
                />
            </CardActionArea>
        </Card>
    );
}