import React, { useEffect, useState, useRef } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getDolphin, selectDolphin} from "../redux/reducers/dolphin";
import { getApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator, httpsCallable  } from "firebase/functions";

let loaded = true

const Get = () => {
  const dispatch = useDispatch();
  const dolphinSel = useSelector(selectDolphin);
  const [dolphin, setDolphin] = useState(null);

  console.log("=== Get ===")
  const functions = getFunctions(getApp());
  connectFunctionsEmulator(functions, "localhost", 5001);
  const getNextDolphin = httpsCallable(functions, 'getNextDolphin');
  getNextDolphin().then((result) => {
    console.log(result)
  });


  // if(loaded){
  //   dispatch(getDolphin())
  //   loaded = false
  // }
  //
  // useEffect(() => {
  //
  //   if(dolphinSel!==null) {
  //     const data = JSON.stringify(dolphinSel.data())
  //     console.log(data)
  //     setDolphin(data)
  //   }
  // }, [dolphinSel]);

  return(
    <div>{dolphin}</div>
  )
}
export default Get