import {Button} from "antd-mobile";
import React from "react";
import styled, { css } from 'styled-components';

const TitleSmall = styled.div`
  text-align: center;
  color: #7E839C;
  padding-top: 10px;
  padding-bottom: 10px;
`

const SupportContainer = styled.div`
  text-align: center;
  padding: 10px;
  background-color: white;
`


const Support = () => {
    const onClickDownload = ()=>{
        window.open('/badaboda_drawing.pdf')
    }
    return(
        <SupportContainer>
            <br/>
            <TitleSmall>직접 후원</TitleSmall>

            <img src={"gilbeot-logo.png"} onClick={()=>{
                window.open("http://hotpinkdolphins.org/", "_blank")
            }} /><br/>

            해양환경단체 핫핑크돌핀스 <br/>
            (63500) 제주특별자치도 서귀포시 대정읍 도원로17번길 5-7 <br/>
            t. 064-772-3366 f. 0303-3442-3366 <br/>
            이메일 hotpinkdolphins@gmail.com <br/><br/>

            국민은행 067501-04-045234<br/>
            <Button onClick={()=>{
                window.open("http://hotpinkdolphins.org/?page_id=26657", "_blank")
            }}  >핫핑크돌핀스 후원하기</Button><br/><br/>

            <img src={"btn_donate.png"} onClick={()=>{
                window.open("https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=AKPWQ3FD4974N", "_blank")
            }} /><br/><br/>

            <TitleSmall>NFT 구매 후원</TitleSmall>

            <img onClick={()=>{
                window.open("https://opensea.io/collection/please-save-my-dolphin", "_blank")
            }}src={"/btn_opensea.png"} />

        </SupportContainer>
    )
}
export default Support