const OpenSee = () => {

    const HTML = `
        <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css" rel="stylesheet" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Newsreader:ital,wght@0,600;1,600&amp;display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,500;0,600;0,700;1,300;1,500;1,600;1,700&amp;display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,400;1,400&amp;display=swap" rel="stylesheet" />
        <link href="css/styles.css" rel="stylesheet" />
        
        <div id="page-top">
            <nav class="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
            <div class="container px-5">
                <a class="navbar-brand fw-bold" href="#page-top">열어, 보다</a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                    <i class="bi-list"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ms-auto me-4 my-3 my-lg-0">
                        <li class="nav-item"><a class="nav-link me-lg-3" href="#features">Features</a></li>
                        <li class="nav-item"><a class="nav-link me-lg-3" href="#download">Download</a></li>
                        <li class="nav-item"><a class="nav-link me-lg-3" href="/gallery">Gallery</a></li>
                        <li class="nav-item"><a class="nav-link me-lg-3" href="/tutorial">Tutorial</a></li>
                    </ul>
 
                </div>
            </div>
        </nav>

        <section class="cta" style="background-image: url('assets/img/000.png');">
            <div class="cta-content">
                <div class="container px-5">
                    <h2 class="text-white display-1 lh-1 mb-4">
                        열어, 보다
                    </h2>
                    <p class="lead fw-normal text-white-50  mb-5 ">남방돌고래를 구하기 위한 기부 프로젝트.</p>
                    <a class="btn btn-outline-light py-3 px-4 rounded-pill" href="#download">Download for free</a>
                </div>
            </div>
        </section>


        <header class="masthead"  style="background-color: #8394F5;">
            <div class="container px-5">
                <div class="row gx-5 align-items-center">
                    <div class="col-lg-6">

                        <div class="mb-5 mb-lg-0 text-center text-lg-start">
                            <h2 class="display-4 lh-1 mb-4 text-white">어디서든 돌고래를 만나보세요.</h2>
                            <p class="lead fw-normal text-white-50  mb-5 ">증강현실을 통해 직접 디자인한 돌고래를 바로 앞에서 만나보실 수 있습니다.</p>

                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="masthead-device-mockup">
                            <img class="img-fluid " src="assets/img/001.png" />
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div style="margin:0em;">

 

        <aside class="text-center" style="background-color:#7286F8"  id="features"> 

            <div class="container px-5" >
                <div class="row gx-5 justify-content-center">
                    <div class="col-xl-8">
                        <div class="h2 fs-1 text-white mb-4">나만의 돌고래를 그려보고 새롭게 만나요.</div>
                        <p class="lead fw-normal text-white-50 mb-5 mb-lg-0">도안을 다운로드 받아 자유롭게 채색해주세요. 어렵지 않아요!</p>
                        <br/>

                        <img class="img-fluid " src="assets/img/002.png"/>
                        <div class="text-center" style="margin-top: 2rem; " >
                            <a class="btn btn-outline-light py-3 px-4 rounded-pill" href="https://tlm.bien.ltd/download" target="_blank">Design | 도안 확인</a>
                        </div>
                    </div>
                </div>
            </div>
        </aside>

        <section style="background-color: #8394F5;"> 
            <div class="container px-5">
                <div class="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                    <div class="col-12 col-lg-5">
                        <h2 class="display-4 lh-1 mb-4 text-white">다른 친구들이 그린 돌고래를 함께 만나보세요.</h2>
                        <p class="lead fw-normal text-white-50 mb-5 mb-lg-0">그림은 느낌과 생각을 자유롭게 표현할 수 있는 훌륭한 의사소통 수단이에요. 그림으로 서로의 마음을 이해해 보아요.</p>
                    </div>
                    <div class="col-sm-8 col-md-6">
                        <div class="px-5 px-sm-0">
                            <img class="img-fluid " src="assets/img/003.png"/>

                        </div>
                    </div>
                </div>

            </div>
            <div class="text-center" style="margin-top: 2rem; " >
                <a class="btn btn-outline-light py-3 px-4 rounded-pill align-items-center text-center" href="https://tlm.bien.ltd/gallery" target="_blank">Gallery | 갤러리 확인</a>
             </div>
        </section>

        <section style="background-color: #7286F8;"> 
            <div class="container px-5">
                <div class="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                    
                    <div class="col-12 col-lg-5">
                        <div class="px-5 px-sm-0">
                            <img class="img-fluid " src="assets/img/004.png" />

                        </div>
                        <p>
                    </div>

                    <div class="col-sm-8 col-md-6">
                        <h2 class="display-4 lh-1 mb-4 text-white">내가 그린 돌고래를 다시 만나볼 수 있어요.</h2>
                        <p class="lead fw-normal text-white-50 mb-5 mb-lg-0">디지털화 된 돌고래는 언제 어디서든 앱을 통해 만나보실 수 있어요.</p>
                    </div>
                </div>
                <div class="d-flex flex-column flex-lg-row align-items-center justify-content-center" style="margin-top: 2rem;">
                    <a class="me-lg-3 mb-4 mb-lg-0" href="https://play.google.com/store/apps/details?id=com.sewonist.tlm&hl=ko" target="_blank">
                        <img class="app-badge" src="assets/img/google-play-badge.svg" alt="..." />
                    </a>
                    <a href="https://apps.apple.com/gb/app/%EA%B2%B9%EC%B3%90%EC%A7%84-%EC%82%AC%EA%B1%B4%EB%93%A4/id1403244493?l=da" target="_blank">
                        <img class="app-badge" src="assets/img/app-store-badge.svg" alt="..." />
                    </a>
                </div>
             </div>
            </div>
        </section>

        <aside class="text-center" style="background-color:#8394F5"  > 

            <div class="container px-5" >
                <div class="row gx-5 justify-content-center">
                    <div class="col-xl-8">
                        <div class="h2 fs-1 text-white mb-4">수익의 일부는 기부됩니다.</div>
                        <p class="text-white-50">NFT기부 서비스를 통해 기부 시스템의 투명성을 제공합니다.</p>

                        <img class="img-fluid " src="assets/img/005.png"/>
                    </div>
                </div>
            </div>
            <div class="text-center" style="margin-top: 2rem; " >
                <a class="btn btn-outline-light py-3 px-4 rounded-pill" href="https://opensea.io/collection/please-save-my-dolphin" style="margin:0.5rem" target="_blank">Registration Status | NFT 등록현황</a>
                <a class="btn btn-outline-light py-3 px-4 rounded-pill" href="https://bien.ltd/bnft/" style="margin:0.5rem" target="_blank">About BNFT | BNFT 소개</a>
            </div>
        </aside>




        <aside class="text-center" style="background-color:#7286F8"  > 

            <div class="container px-5" >
                <div class="row gx-5 justify-content-center">
                    <div class="col-xl-8">
                        <h2 class="display-4 lh-1 mb-4 text-white" style="text-align: center !important;">진행방법</h2>
                        <p class="lead fw-normal text-white-50 mb-5 mb-lg-0">그림을 그려 사진만 등록해주세요. 나머지는 BNFT가 알아서 다 해드려요!</p>

                        <br/>
                    </div>
                    <br/>

                    <div class="col-lg-10 order-lg-0 mb-1 mb-lg-0">
                        <div class="container-fluid px-5">
                            <div class="row gx-5">
                                <div class="col-md-6">
                                    <div class="text-center">
                                        <img class="img-fluid" src="assets/img/process_01.png" /><p/>
                                        <h4 class="font-alt text-white">그림등록</h4>
                                        <p class="mb-0 text-white-50">예쁜 돌고래를 그려서 등록해주세요!</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="text-center">
                                        <img class="img-fluid" src="assets/img/process_02.png" /><p/>
                                        <h4 class="font-alt text-white">3D 데이터 변환</h4>
                                        <p class=" mb-0 text-white-50">등록된 돌고래가 자동으로 3D데이터로 변환되어 AR로 확인하실 수 있어요!</p>
                                    </div>
                                </div>
                                <div class="col-md-6 ">
                                    <div class="text-center">
                                        <img class="img-fluid" src="assets/img/process_03.png" /><p/>
                                        <h4 class="font-alt text-white">NFT 등록</h4>
                                        <p class=" mb-0 text-white-50">예쁜 돌고래들은 NFT로 등록되어 판매됩니다.</p>
                                    </div>
                                </div>
                                <div class="col-md-6">

                                    <div class="text-center">

                                        <img class="img-fluid" src="assets/img/process_04.png"/>
                                        <p/>
                                        <h4 class="font-alt text-white">기부</h4>
                                        <p class="mb-0 text-white-50">판매수익금은 돌고래들을 위해 기부됩니다.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </aside>

<div class="video-container">

 <iframe width="560" height="315" src="https://www.youtube.com/embed/gTVTUXg6irY" alt="O9AqwevXuBA" frameborder="0" allowfullscreen=""></iframe>

</div>

</div>



        <section style="background-color: #8394F5;"  id="download">
            <div class="container px-5">
                <h2 class="text-center text-white font-alt mb-4">Get the app now!</h2>
                <div class="d-flex flex-column flex-lg-row align-items-center justify-content-center">
                    <a class="me-lg-3 mb-4 mb-lg-0" href="https://play.google.com/store/apps/details?id=com.sewonist.tlm&hl=ko" target="_blank">
                        <img class="app-badge" src="assets/img/google-play-badge.svg" alt="..." />
                    </a>
                    <a href="https://apps.apple.com/app/id1403244493" target="_blank">
                        <img class="app-badge" src="assets/img/app-store-badge.svg" alt="..." />
                    </a>
                </div>
            </div>
        </section>

        <footer class="bg-black text-center py-5">
            <div class="container px-5">
                <div class="text-white-50 small">
                    <div class="mb-2">&copy; BIEN P.R. 2022. All Rights Reserved.</div>
                    <a href="https://bien.ltd/privacy_seasee.html" target="_blank">Privacy</a>
                    <span class="mx-1">&middot;</span>
                    <a href="https://bien.ltd/terms_seasee.html" target="_blank">Terms</a>

                </div>
            </div>
        </footer>

        <div class="modal fade" id="feedbackModal" tabindex="-1" aria-labelledby="feedbackModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header bg-gradient-primary-to-secondary p-4">
                        <h5 class="modal-title font-alt text-white" id="feedbackModalLabel">Send feedback</h5>
                        <button class="btn-close btn-close-white" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body border-0 p-4">

                        <form id="contactForm" data-sb-form-api-token="API_TOKEN">

                            <div class="form-floating mb-3">
                                <input class="form-control" id="name" type="text" placeholder="Enter your name..." data-sb-validations="required" />
                                <label for="name">Full name</label>
                                <div class="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                            </div>
                            <div class="form-floating mb-3">
                                <input class="form-control" id="email" type="email" placeholder="name@example.com" data-sb-validations="required,email" />
                                <label for="email">Email address</label>
                                <div class="invalid-feedback" data-sb-feedback="email:required">An email is required.</div>
                                <div class="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
                            </div>

                            <div class="form-floating mb-3">
                                <input class="form-control" id="phone" type="tel" placeholder="(123) 456-7890" data-sb-validations="required" />
                                <label for="phone">Phone number</label>
                                <div class="invalid-feedback" data-sb-feedback="phone:required">A phone number is required.</div>
                            </div>
                            <div class="form-floating mb-3">
                                <textarea class="form-control" id="message" type="text" placeholder="Enter your message here..." style="height: 10rem" data-sb-validations="required"></textarea>
                                <label for="message">Message</label>
                                <div class="invalid-feedback" data-sb-feedback="message:required">A message is required.</div>
                            </div>
                           
                            <div class="d-none" id="submitSuccessMessage">
                                <div class="text-center mb-3">
                                    <div class="fw-bolder">Form submission successful!</div>
                                    To activate this form, sign up at
                                    <br />
                                    <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                                </div>
                            </div>
                           
                            <div class="d-none" id="submitErrorMessage"><div class="text-center text-danger mb-3">Error sending message!</div></div>

                            <div class="d-grid"><button class="btn btn-primary rounded-pill btn-lg disabled" id="submitButton" type="submit">Submit</button></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        
    
        </div>
    `

    return(
        <div dangerouslySetInnerHTML={{__html: HTML}}/>
    )
}
export default OpenSee