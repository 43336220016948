import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {collection, getDoc, getDocs, getFirestore, limit, orderBy, query, where} from "firebase/firestore";

export const getDolphin = createAsyncThunk(
  "getDolphin",
  async (params, { dispatch, getState }) => {
    const db = getFirestore()
    const q = query(
      collection(db, "dolphins"),
      orderBy("create"),
    );
    const dolphins = await getDocs(q);
    for (let i = 0; i < dolphins.size; i++) {
      const doc = dolphins.docs[i]
      if(doc.data().status==="upload"){
        dispatch(setDolphin(doc));
        break
      }
    }
  }
);

const initialState = {
  doc: null,
};

const dolphin = createSlice({
  name: "dolphin",
  initialState,
  reducers: {
    setDolphin: (state, action) => {
      state.doc = action.payload
    },
  },
});

//actions
export const {
  setDolphin,
} = dolphin.actions;

//selectors
export const selectDolphin = (state) => state.dolphin.doc;

export default dolphin.reducer;
