import {collection, getDocs, getFirestore, orderBy, query} from "firebase/firestore";
import {Suspense, useEffect, useState} from "react";
import {Canvas, useFrame} from '@react-three/fiber'
import * as THREE from "three"
import Dolphin from "./Dolphin"
import TotalCard from "./TotalCard";
import {App, AppTitle, Title} from "./Style";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Gallery = () => {
    const [total, setTotal] = useState(0)
    const [snapshot, setSnapShot] = useState(null)
    const db = getFirestore();

    const getTotal = async () => {
        const ref = collection(db, "dolphins");
        const q = query(ref, orderBy("create", "desc"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
        });
        console.log("Total: " + querySnapshot.size)
        setTotal(querySnapshot.size)
        setSnapShot(querySnapshot)
    }

    useEffect(()=>{
        getTotal()
    }, [])

    const Rig = ()=>{
        return useFrame((state) => {
            state.camera.position.x = THREE.MathUtils.lerp(state.camera.position.x, 1 + state.mouse.x / 4, 0.075)
            state.camera.position.y = THREE.MathUtils.lerp(state.camera.position.y, 1.5 + state.mouse.y / 4, 0.075)
        })
    }

    return(
        <>
            <Navbar bg="light" expand="lg" fixed="top">
                <Container>
                    <Navbar.Brand href="/#page-top">열어,보다</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" >
                        <Nav className="me-auto">
                        </Nav>
                        <Nav >
                            <Nav.Link href="/#features">Features</Nav.Link>
                            <Nav.Link href="/#download">Download</Nav.Link>
                            <Nav.Link href="/gallery">Gallery</Nav.Link>
                            <Nav.Link href="/tutorial">Tutorial</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <App>
                <Canvas concurrent shadowMap camera={{ position: [1, 1.5, 2.5], fov: 50 }}>
                    <ambientLight />
                    <directionalLight position={[-5, 5, 5]} castShadow shadow-mapSize-width={1024} shadow-mapSize-height={1024} />
                    <group position={[0, -1, 0]}>
                        <Suspense fallback={null}>
                            <Dolphin />
                        </Suspense>
                    </group>
                    <mesh rotation={[-0.5 * Math.PI, 0, 0]} position={[0, -1, 0]} receiveShadow>
                        <planeBufferGeometry args={[10, 10, 1, 1]} />
                        <shadowMaterial transparent opacity={0.2} />
                    </mesh>
                    <Rig />
                </Canvas>
            </App>

            <AppTitle> </AppTitle>
            <Title>Gallery</Title>

            <TotalCard data={snapshot} />


            {/*<Footer />*/}
        </>
    )
}
export default Gallery