import styled from "styled-components";

export const App = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
`
export const Title = styled.div`
  position: relative;
  pointer-events: none;
  z-index: 1001;
  width: 80vw;
  margin: auto;
  font-family: 'Playfair Display', serif;
  font-size: 128px;
  line-height: 92px;
  padding-bottom: 110px;
  @media screen and (max-width: 768px) {
    font-size: 64px;
    line-height: 64px;
    padding-bottom: 110px;
  }
`

export const AppTitle = styled.div`
  position: relative;
  pointer-events: none;
  z-index: 1002;
  width: 80vw;
  margin: auto;
  font-family: 'Playfair Display', serif;
  font-size: 42px;
  padding-top: 30px;
  padding-bottom: 30px;
  @media screen and (max-width: 768px) {
    font-size: 24px;
    padding-bottom: 10px;
  }
`
