import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import dolphin from "./reducers/dolphin"

const middlewares = [thunk];
// const middlewares = [logger, thunk];

const store = configureStore({
  reducer: { dolphin  },
  middleware: [...middlewares],
});

export default store;
