import {AboutH2, AboutH3, Background, Bolder, Comment, Page} from "./AboutStyle";
import {Box, Grid} from "@mui/material";
import styled from "styled-components";
const FooterLink = styled.a`
  color: #000000;
  font-size: 14px;
`
const Divider = styled.span`
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  color: #000000;
`
const Page007 = ()=>{
    return(
        <div style={{backgroundColor: "#7286F8"}}>
            <Page>
                <Grid container spacing={1} sx={{height: '100%'}} >
                    <Grid item sx={{height: '5%'}} xs={12} />
                    <Grid item sx={{height: '20%'}} xs={12}
                          justifyContent="center"
                          alignItems="center"
                    >
                        <AboutH2><Bolder>진행방법</Bolder></AboutH2>
                        <AboutH3>
                            그림을 그려 사진만 등록해주세요. <Bolder>나머지는 BNFT가 알아서 다</Bolder> 해드려요!
                        </AboutH3>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} spacing={1} >
                        <img src={"/about/page007_step01.png"} width={"100%"} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} spacing={1} >
                        <img src={"/about/page007_step02.png"} width={"100%"}  />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} spacing={1} >
                        <img src={"/about/page007_step03.png"} width={"100%"}  />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} spacing={1} >
                        <img src={"/about/page007_step04.png"} width={"100%"}  />
                    </Grid>

                    <Grid item sx={{height: '20%'}} xs={12}>
                        <Box display="flex"
                             justifyContent="center"
                             alignItems="center"
                             minHeight="100%">
                            <a href="https://apps.apple.com/app/id1403244493"
                               target="_blank" rel="noreferrer">
                                <img src={"app-store-badge-kr.png"} width={128} />
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="https://play.google.com/store/apps/details?id=com.sewonist.tlm"
                               target="_blank" rel="noreferrer">
                                <img src={"google-play-badge-kr.png"} width={128} />
                            </a>
                        </Box>
                    </Grid>
                    <Grid item sx={{height: '10%'}} xs={12}>
                        <Box display="flex"
                             justifyContent="center"
                             alignItems="center"
                             minHeight="100%">
                            <FooterLink href={"/terms"} target={"_blank"}>이용약관</FooterLink>
                            <Divider>|</Divider>
                            <FooterLink href={"https://bien.ltd/privacy_seasee.html"} target={"_blank"}>개인정보처리방침</FooterLink>
                        </Box>
                    </Grid>
                </Grid>
            </Page>

        </div>
    )
}

export default Page007